<template>
  <div>
    <a-descriptions title="个人信息" bordered>
      <a-descriptions-item label="姓名" :span="1.5">
        {{ userInfo.userName }}
      </a-descriptions-item>
      <a-descriptions-item label="性别" :span="1.5">
        {{ userInfo.sex_dictText }}
      </a-descriptions-item>
      <a-descriptions-item label="部门名称" :span="1.5">
        {{ userInfo.deptName }}
      </a-descriptions-item>
      <a-descriptions-item label="电话" :span="1.5">
        {{ userInfo.phone }}
      </a-descriptions-item>
      <a-descriptions-item label="职务" :span="1.5">
        {{ userInfo.duty }}
      </a-descriptions-item>
      <a-descriptions-item label="职称" :span="1.5">
        {{ userInfo.professional_dictText }}
      </a-descriptions-item>
      <a-descriptions-item label="编制" :span="1.5">
        {{ userInfo.structural }}
      </a-descriptions-item>
      <a-descriptions-item label="学位" :span="1.5">
        {{ userInfo.degree_dictText }}
      </a-descriptions-item>
      <!-- <a-descriptions-item label="聘用方式
" :span="1.5">
        {{ userInfo.engageWay_dictText }}
      </a-descriptions-item> -->
      <a-descriptions-item label="专业" :span="1.5">
        {{ userInfo.major }}
      </a-descriptions-item>
      <a-descriptions-item label="电子邮件" :span="1.5">
        {{ userInfo.email }}
      </a-descriptions-item>
    </a-descriptions>
    <div style="text-align:right;margin-top:10px;">
      <a-button type="primary" @click="showModal" >
        密码修改
      </a-button>
      <a-button type="primary" style="margin-left:10px;" @click="showUpdateModal" >
        个人信息修改
      </a-button>
    </div>

    <password ref="password" :message="false" />

    <a-modal
      v-model="updateShow"
      title="修改"
      width="1000px"
      @ok='updateOk'
    >
      <update
        ref='update'
        :currentTable="currentTable"
      ></update>
    </a-modal>
  </div>
</template>

<script>
import api from './api'
// import { initGridFormData } from '@/common/tools'
// import cookieUtil from '@/common/cookie-util'
import password from './password.vue'
import update from '../components/personnel/update.vue'
export default {
  provide () {
    return {
      changeValue: () => {},
      getList: this.getList
    }
  },
  components: {
    password,
    update
  },
  data () {
    return {
      userInfo: [],
      updateShow: false,
      currentTable: {}
    }
  },
  mounted () {
    this.getUserInfo()
  },
  methods: {
    getList () {
      this.updateShow = false
      this.getUserInfo()
    },
    getUserInfo () {
      api.getUserInfo(this.$route.query.id).then(res => {
        this.userInfo = res.data
      })
    },

    showUpdateModal () {
      this.updateShow = true
      this.currentTable = this.userInfo
    },

    updateOk () {
      this.$refs.update.submit()
    },

    showModal () {
      this.$refs.password.showModal()
    }
  }
}
</script>

<style>
</style>
