<template>
  <a-modal v-model="visible" title="修改密码" :maskClosable="false" :closable="closable" @ok="handleOk"
    @cancel="handleCancel">
    <div>
      <div v-if="message">
        <a-alert message="登录为初始密码，请修改密码！" style="margin-bottom:20px;" banner />
      </div>
      <ax-form ref="formBox" :formBuilder="formBuilder">
      </ax-form>
    </div>
  </a-modal>
</template>

<script>
import api from './api'
import { initGridFormData } from '@/common/tools'
import cookieUtil from '@/common/cookie-util'
import { mapGetters } from 'vuex'
export default {
  props: {
    closable: {
      type: Boolean,
      default: true
    },
    logout: {
      type: Boolean,
      default: false
    },
    message: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      visible: false,
      userInfoData: {},
      formBuilder: initGridFormData(
        [
          {
            label: '原密码',
            type: 'inputPassword',
            model: 'password',
            options: {},
            formItem: {},
            col: { span: 24 },
            rules: [{ required: true, message: '请输入原密码' }]
          },
          {
            label: '新密码',
            type: 'inputPassword',
            model: 'newPassword',
            options: {},
            formItem: {},
            col: { span: 24 },
            rules: [
              { required: true, message: '请输入新密码' },
              { required: false, pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/, message: ' 密码至少包含 数字和英文，长度6-20' }
            ]
          },
          {
            label: '确认新密码',
            type: 'inputPassword',
            model: 'affirmNewPassword',
            options: {},
            formItem: {},
            col: { span: 24 },
            rules: [{ required: true, message: '请确认新密码' }]
          }
        ],
        {
          layout: 'horizontal',
          labelWidth: 120
        }
      )
    }
  },
  computed: {
    ...mapGetters('app', ['userInfo'])
  },
  mounted () {
    this.getUserInfo()
  },
  methods: {
    showModal () {
      this.visible = true
    },
    handleCancel () {
      this.visible = false
      if (this.logout) {
        this.$message.error('需修改默认密码！')
        this.handleLogout()
      }
    },

    getUserInfo () {
      api.getUserInfo(this.userInfo.id).then(res => {
        this.userInfoData = res.data
      })
    },

    handleOk (e) {
      this.$refs.formBox.form.validateFields(async (errors, values) => {
        if (!errors) {
          console.log(values, 'values')
          if (values.newPassword !== values.affirmNewPassword) {
            this.$message.error('密码输入不一致')
            return
          }
          api.updatePasswrod({ ...values, id: this.userInfoData.id }).then(res => {
            this.$message.success('密码修改成功， 请重新登录！')
            this.visible = false
            this.handleLogout()
          }).catch(() => { })
        }
      })
    },

    // 退出登录
    handleLogout () {
      setTimeout(() => {
        this.$apis.components.logout().then(res => {
          cookieUtil.removeToken()
          location.reload()
        }).catch(e => {
          this.$message.error('操作失败!')
        })
      }, 1000)
    }
  }
}
</script>

<style>
</style>
